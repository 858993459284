import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CategoryList.css";
import { useNavigate } from "react-router-dom";

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`
        );
        const productsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/products`
        );

        // Agregar información de disponibilidad según los productos existentes
        const updatedCategories = categoriesResponse.data.categories.map(
          (category) => {
            const categoryProducts = productsResponse.data.filter(
              (product) => product.categoryId === category.id
            );
            return {
              ...category,
              isAvailable: categoryProducts.length > 0, // Verificar si hay productos en la categoría
            };
          }
        );

        setCategories(updatedCategories);
      } catch (error) {
        console.error("Hubo un error al obtener las categorías:", error);
        setCategories([]);
      }
    };

    fetchCategoriesAndProducts();
  }, []);

  return (
    <div className="categories-container">
      <div className="category-list">
        {categories.map((category) => (
          <div key={category.id} className="category-item">
            <h3>{category.name}</h3>
            <button>
              {category.isAvailable ? "Ver productos" : "Stock agotado"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryList;
