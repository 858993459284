import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Store.css"; // Añadir el archivo CSS
import ProductList from "./ProductList";

function Store() {
  return (
    <>
      <ProductList />
    </>
  );
}

export default Store;
