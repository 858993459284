import React, { useState, useEffect } from "react";
import "./About.css";

const banners = [{ src: "about-banner.webp", alt: "Banner 1" }];
const mobileBanner = { src: "qsv.jpg", alt: "Mobile Banner" };

function About() {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Cambiar el banner cada 5 segundos (si está descomentado)
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
  //   }, 3000);

  //   return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="about-container">
      {/* Slider */}
      <div className="banner-slider">
        <div className="banner-slide active">
          <img src={isMobile ? mobileBanner.src : banners[currentBanner].src} alt={banners[currentBanner].alt} />
        </div>
      </div>

      {/* Contenido debajo del slider */}
      <div className="about-content">
        <h2>CON LA MONA, SIEMPRE ESTARÁS DE LO MÁS MONA.</h2>
        <p>
          La Mona es una marca de accesorios artesanales que nació en 2023, con
          el propósito de ofrecer piezas únicas que resalten la belleza y el
          estilo personal de cada mujer. Inspirada en la búsqueda de accesorios
          distintivos, nuestra marca se especializa en mostacillas y materiales
          de alta calidad.
        </p>
        <br />
        <p>
          Cada uno de nuestros productos es 100% artesanal, elaborado por
          talentosas mujeres artesanas peruanas que preservan técnicas
          tradicionales. Utilizamos mostacillas checas y hilos de 100% algodón,
          asegurando que cada pieza sea única y duradera.
        </p>
        <br />
        <p>
          Nos enorgullece la originalidad de nuestros diseños y el esmero en
          cada detalle. Nuestra misión es que cada mujer que luzca nuestros
          accesorios se sienta auténtica y empoderada. Con miras a expandirnos
          internacionalmente, soñamos con compartir nuestra esencia y el trabajo
          artesanal que hay detrás de cada creación.
        </p>
      </div>
    </div>
  );
}

export default About;
