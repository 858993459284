import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Para la navegación
import "./ProductList.css";

function ProductList() {
  const [products, setProducts] = useState([]);
  const [hoveredProductId, setHoveredProductId] = useState(null); // Para almacenar el producto que está siendo hoverado
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error al cargar productos:", error);
      });
  }, []);

  const handleProductClick = (id, productSlug) => {
    navigate(`/tienda/${id}/${productSlug}`); // Navega a la ruta con el slug del producto
  };

  // Función para convertir el nombre a un slug
  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Reemplaza espacios con guiones
      .replace(/[^\w\-]+/g, "") // Remueve caracteres especiales
      .replace(/\-\-+/g, "-") // Reemplaza múltiples guiones por uno
      .replace(/^-+/, "") // Remueve guiones al principio
      .replace(/-+$/, ""); // Remueve guiones al final
  };

  return (
    <div className="product-grid-container">
      {products.length === 0 ? (
        <p>Cargando productos.</p>
      ) : (
        products.map((product) => (
          <div
            key={product.id}
            className={`product-item ${product.stock === 0 ? "out-of-stock" : ""}`} // Aplicar clase si está agotado
            onClick={() => handleProductClick(product.id, slugify(product.name))} // Asignar el slug al hacer clic
            onMouseEnter={() => setHoveredProductId(product.id)} // Establecer el producto hoverado
            onMouseLeave={() => setHoveredProductId(null)} // Restablecer al salir del hover
          >
            <img
              src={hoveredProductId === product.id ? product.image2 : product.image1}
              alt={product.name}
              className="product-image"
            />
            <h3 className="product-name">
              <p>{product.name}</p>
              <p>S/. {product.price}</p>
            </h3>

            {/* Si el producto está agotado, mostrar el overlay */}
            {product.stock === 0 && (
              <div className="sold-out-overlay">
                <span>Agotado</span>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default ProductList;
