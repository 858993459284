import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ClientsPage.css"; // Agregar estilos CSS específicos

function ClientsPage() {
  const [clients, setClients] = useState([]);

  // Función para obtener todos los clientes
  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/clients`);
      setClients(response.data);
    } catch (error) {
      console.error("Error al cargar los clientes:", error);
    }
  };

  return (
    <div className="clients-page">
      <h2>Administrar Clientes</h2>
      {clients.length === 0 ? (
        <p>No hay clientes disponibles</p>
      ) : (
        <table className="clients-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Apellidos</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>DNI</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.id}>
                <td>{client.id}</td>
                <td>{client.nombre}</td>
                <td>{client.apellidos}</td>
                <td>{client.email}</td>
                <td>{client.telefono}</td>
                <td>{client.dni}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ClientsPage;
