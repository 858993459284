import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OrdersPage.css"; // Archivo CSS para estilos

function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null); // Orden seleccionada para ver detalles
  const [showModal, setShowModal] = useState(false); // Controla la visibilidad del modal
  const [status, setStatus] = useState(""); // Estado de pedido a actualizar

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders`
      );
      setOrders(response.data);
    } catch (error) {
      console.error("Error al cargar órdenes:", error);
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdateStatus = async (orderId) => {
    try {
      const stockAdjustment = {
        shouldRestock: false, // No sumar el stock inicialmente
        shouldReduceStock: false, // No restar el stock inicialmente
      };

      // Obtener el estado actual de la orden antes de cambiarlo
      const currentOrder = orders.find((order) => order.id === orderId);
      const currentStatus = currentOrder ? currentOrder.status : null;

      // Si se cancela la orden o vuelve a estado pendiente, sumar el stock
      if (status === "Failed" || status === "Pending") {
        if (
          currentStatus === "Completed" ||
          currentStatus === "Shipped" ||
          currentStatus === "Delivered"
        ) {
          stockAdjustment.shouldRestock = true;
        }
      }

      // Si el estado cambia a Pagado, Enviado o Entregado y no se ha reducido antes, restar el stock
      if (
        (status === "Completed" ||
          status === "Shipped" ||
          status === "Delivered") &&
        currentStatus !== "Completed" &&
        currentStatus !== "Shipped" &&
        currentStatus !== "Delivered"
      ) {
        stockAdjustment.shouldReduceStock = true;
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
        status,
        ...stockAdjustment, // Enviar ajustes de stock
      });

      fetchOrders(); // Actualizar la lista de órdenes después del cambio de estado
      setShowModal(false);
    } catch (error) {
      console.error("Error al actualizar el estado de la orden:", error);
    }
  };

  const handleShowModal = (order) => {
    setSelectedOrder(order);
    setStatus(order.status); // Cargar el estado actual en el modal
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="orders-page">
      <h2>Administrar Órdenes</h2>
      {orders.length === 0 ? (
        <p>No hay órdenes disponibles</p>
      ) : (
        <table className="order-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Cliente</th>
              <th>Total</th>
              <th>Status</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>
                  {order.Client.nombre} {order.Client.apellidos}
                </td>
                <td>{order.total_amount} Soles</td>
                <td>{order.status === "Completed" ? "Pagado" : order.status}</td>
                <td>
                  <button
                    className="btn-view-details"
                    onClick={() => handleShowModal(order)}
                  >
                    Ver Detalles
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {showModal && selectedOrder && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3>Orden #{selectedOrder.id}</h3>
              <button className="close-button" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              {/* Información del cliente */}
              <h4>Cliente:</h4>
              <p>
                {selectedOrder.Client.nombre} {selectedOrder.Client.apellidos}
              </p>
              <p>Email: {selectedOrder.Client.email}</p>
              <p>Teléfono: {selectedOrder.Client.telefono}</p>

              {/* Dirección de Envío */}
              <h4>Dirección de Envío:</h4>
              <p>
                {selectedOrder.Delivery.address},{" "}
                {selectedOrder.Delivery.number}
              </p>
              <p>
                {selectedOrder.Delivery.district},{" "}
                {selectedOrder.Delivery.province},{" "}
                {selectedOrder.Delivery.department}
              </p>

              {/* Productos */}
              <h4>Productos:</h4>
              <ul>
                {selectedOrder.Products.map((product) => (
                  <li key={product.id}>
                    {product.name} - {product.OrderProducts.quantity} unidades -
                    Precio: {product.price} Soles
                  </li>
                ))}
              </ul>

              {/* Estado del Pedido */}
              <label htmlFor="orderStatus">Estado</label>
              <select
                id="orderStatus"
                value={status || selectedOrder.status}
                onChange={handleStatusChange}
              >
                <option value="Pending">Pendiente</option>
                <option value="Completed">Pagado</option>
                <option value="Shipped">Enviado</option>
                <option value="Delivered">Entregado</option>
                <option value="Failed">Cancelado</option>
              </select>
            </div>
            <div className="modal-footer">
              <button className="btn" onClick={handleCloseModal}>
                Cerrar
              </button>
              <button
                className="btn-primary"
                onClick={() => handleUpdateStatus(selectedOrder.id)}
              >
                Actualizar Estado
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrdersPage;
