import React from 'react';

const Pending = () => {
  return (
    <div className="thank-you-container">
      <h2>Pago Pendiente</h2>
      <p>Tu pago está en proceso de validación. Recibirás una notificación cuando el pago haya sido procesado.</p>
      <p>Si realizaste un pago en efectivo, este puede tardar algunos minutos en procesarse.</p>
      <p>Gracias por tu paciencia.</p>
    </div>
  );
};

export default Pending;
