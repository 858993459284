import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CategoryList from "./components/CategoryList";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import { ShoppingProvider } from "./context/ShoppingContext";
import { AuthProvider } from "./context/AuthContext";
import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import Collections from "./components/Collections";
import Dashboard from "./admin/Dashboard";
import ProductsPage from "./admin/ProductsPage";
import CategoriesPage from "./admin/CategoriesPage";
import CollectionsPage from "./admin/CollectionsPage";
import UsersPage from "./admin/UsersPage";
import OrdersPage from "./admin/OrdersPage";
import Layout from "./Layout"; // Importa el nuevo layout
import Store from "./components/Store";
import Collection from "./components/Collection";
import ProductDetails from "./components/ProductDetails";
import Checkout from "./components/Checkout";
import CheckoutPayment from "./components/CheckoutPayment";
import ThankYou from "./components/ThankYou";
import ClientsPage from "./admin/ClientsPage";
import Failure from "./components/Failure";
import Pending from "./components/Pending";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <AuthProvider>
      <ShoppingProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/tienda" element={<Store />} />
              <Route path="/tienda/:id/:slug" element={<ProductDetails />} />
              <Route path="/categories" element={<CategoryList />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/colecciones" element={<Collections />} />
              <Route path="/colecciones/:id/:slug" element={<Collection />} />
              <Route 
                path="/dashboard" 
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              >
                <Route path="products" element={<ProductsPage />} />
                <Route path="categories" element={<CategoriesPage />} />
                <Route path="collections" element={<CollectionsPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="orders" element={<OrdersPage />} />
                <Route path="clients" element={<ClientsPage />} />
              </Route>
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/checkout/payment" element={<CheckoutPayment />} />
              <Route path="/success" element={<ThankYou />} />
              <Route path="/failure" element={<Failure />} />
              <Route path="/pending" element={<Pending />} />
            </Routes>
          </Layout>
        </Router>
      </ShoppingProvider>
    </AuthProvider>
  );
}

export default App;
