import React, { useState, useEffect } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

const desktopBanners = [
  { src: "web01.png", alt: "Banner 1" },
  { src: "web02.png", alt: "Banner 2" },
  { src: "web03.png", alt: "Banner 3" },
//   { src: "web04.png", alt: "Banner 4" },
];

const mobileBanners = [
  { src: "web-vertical-01.jpg", alt: "Banner 1" },
  { src: "web-vertical-02.jpg", alt: "Banner 2" },
  { src: "web-vertical-03.jpg", alt: "Banner 3" },
//   { src: "web-vertical-04.png", alt: "Banner 4" },
];

function Home() {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [banners, setBanners] = useState(desktopBanners);

  // Cambiar el banner cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    }, 3000);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
  }, [banners]);

  // Detectar el cambio de tamaño de pantalla
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setBanners(mobileBanners);
      } else {
        setBanners(desktopBanners);
      }
    };

    // Ejecutar al montar el componente y en cada cambio de tamaño
    window.addEventListener("resize", handleResize);

    // Ejecutar inmediatamente al cargar la página para configurar los banners correctos
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div className="home-container">
      {/* Slider de banners */}
      <div className="banner-slider" onClick={() => navigate("/tienda")}>
        {banners.map((banner, index) => (
          <div
            key={index}
            className={`banner-slide ${index === currentBanner ? "active" : ""}`}
          >
            <img src={banner.src} alt={banner.alt} />
          </div>
        ))}
      </div>

      {/* Contenido debajo del slider */}
      <div className="home-content">
        <h2>LA MONA ES</h2>
        <p>
          Una marca de accesorios artesanales fundada en 2023, dedicada a
          ofrecer piezas únicas que realzan la belleza y el estilo de cada
          mujer. Cada producto es 100% artesanal, elaborado por talentosas
          mujeres artesanas peruanas que preservan técnicas tradicionales.
        </p>
        <br></br>
        <p>
          Utilizamos mostacillas checas y hilos de 100% algodón, asegurando que
          cada pieza sea única y duradera. Nuestra misión es empoderar a cada
          mujer que luzca nuestros accesorios, compartiendo la esencia del
          trabajo artesanal que hay detrás de cada creación.
        </p>
      </div>
    </div>
  );
}

export default Home;
