import React, { useContext, useEffect, useState } from "react";
import { ShoppingContext } from "../context/ShoppingContext"; // Import the context
import "./Checkout.css"; // Import the CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const {
    cart,
    increaseQuantity,
    decreaseQuantity,
    removeFromCart,
    calculateTotal,
  } = useContext(ShoppingContext);
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  // Effect to detect screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Set initial value
    handleResize();

    // Add event listener on resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDecreaseQuantity = (productId) => {
    const cartItem = cart.find((item) => item.id === productId);
    if (cartItem.quantity === 1) {
      removeFromCart(productId);
    } else {
      decreaseQuantity(productId);
    }
  };

  if (cart.length === 0) {
    return <p>Tu carrito está vacío.</p>;
  }

  return (
    <div className="checkout-container">
      <h1 className="checkout-title">MI CARRITO</h1>
      <table className="checkout-table">
        <thead>
          <tr>
            <th>PRODUCTOS</th>
            {!isMobile && <th>cantidad</th>}
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((product) => (
            <tr key={product.id} className="checkout-item">
              <td className="checkout-product-details">
                <img
                  src={product.image1}
                  alt={product.name}
                  className="checkout-product-image"
                />
                <div>
                  <h3>{product.name}</h3>
                  {isMobile && (
                    <div className="flex-column">
                      <div
                        className="quantity-btn"
                        onClick={() => handleDecreaseQuantity(product.id)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </div>
                      <div className="quantity-display">{product.quantity}</div>
                      <div
                        className="quantity-btn"
                        onClick={() => increaseQuantity(product.id)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>
                  )}
                  {!isMobile && <p>{product.description}</p>}
                </div>
              </td>
              {!isMobile && (
                <td>
                  <div className="flex-column">
                    <button
                      className="quantity-btn"
                      onClick={() => handleDecreaseQuantity(product.id)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <div className="quantity-display">{product.quantity}</div>
                    <button
                      className="quantity-btn"
                      onClick={() => increaseQuantity(product.id)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </td>
              )}
              <td className="checkout-total">S/. {product.price.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="checkout-footer">
        <div className="checkout-total-display">
          <p>Total: S/. {calculateTotal().toFixed(2)}</p>
        </div>
        <button className="checkout-btn" onClick={() => navigate("payment")}>
          COMPRAR
        </button>
      </div>
    </div>
  );
};

export default Checkout;
