import React, { useContext } from "react";
import { ShoppingContext } from "../context/ShoppingContext";
import { useNavigate } from "react-router-dom";
import "./MiniCart.css"; // Asegúrate de crear este archivo CSS

const MiniCart = ({ closeCart }) => {
  const { showCart, setShowCart, cart } = useContext(ShoppingContext); // Obtener el carrito desde el contexto
  const navigate = useNavigate();

  if (cart.length === 0) {
    return (
      <div className="mini-cart-container">
        <p>Tu carrito está vacío.</p>
        <button className="close-btn" onClick={() => closeCart()}>
          Cerrar
        </button>
      </div>
    );
  }

  return (
    <div className="mini-cart-container">
      <div className="close-btn" onClick={() => closeCart()}>
        CLOSE
      </div>
      <div className="cart-items">
        {cart.map((product) => (
          <div
            key={product.id}
            className="cart-item"
            onClick={() => navigate(`/tienda/${product.id}/${product.slug}`)} // Navegar a la ruta del producto
          >
            <img
              src={product.image1}
              alt={product.name}
              className="cart-item-image"
            />
            <div className="cart-item-details">
              <h3>{product.name}</h3>
              <p>Cantidad de unidades: {product.quantity}</p>
            </div>
            <div className="cart-item-price">
              <p>S/. {product.price}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="checkout-btn" onClick={() => {navigate("/checkout");
      setShowCart(!showCart);
      }
      }>
        IR A PAGAR
      </button>
      <button className="continue-shopping-btn" onClick={() => {navigate("/tienda"); closeCart()}}>
        CONTINUAR COMPRANDO
      </button>
    </div>
  );
};

export default MiniCart;
