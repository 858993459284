import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ShoppingContext } from "../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "./ProductDetails.css";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    addToCart,
    cart,
    increaseQuantity,
    decreaseQuantity,
    removeFromCart,
    setShowCart,
  } = useContext(ShoppingContext);

  const [product, setProduct] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0); // Track current image index
  const cartItem = cart.find((item) => item.id === product?.id);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/${id}`
        );
        setProduct(productResponse.data);
      } catch (error) {
        console.error("Error al cargar los detalles del producto:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  // Prepare images array, filtering out null or undefined values
  const images = [product?.image1, product?.image2, product?.image3, product?.image4].filter(
    (image) => image
  );

  const handleDecreaseQuantity = (productId) => {
    if (cartItem.quantity === 1) {
      removeFromCart(productId);
    } else {
      decreaseQuantity(productId);
    }
  };

  const handleIncreaseQuantity = (productId) => {
    if (cartItem.quantity < product.stock) {
      increaseQuantity(productId);
    }
  };

  // Slider control functions
  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Move to next image
  };

  const goToPreviousImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); // Move to previous image
  };

  if (!product) {
    return <p style={{ marginTop: "45px" }}>Cargando detalles del producto...</p>;
  }

  return (
    <div className="product-details-container">
      {/* Image Slider Section */}
      <div className="product-image-slider">
        <div
          className="slider-wrapper"
          style={{ transform: `translateX(-${currentIndex * 100}%)`, width: `${100}%` }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${product.name} ${index + 1}`}
              className="product-image-slide"
            />
          ))}
        </div>
        {/* Navigation arrows */}
        {images.length > 1 && (
          <>
            <button className="slider-btn prev-btn" onClick={goToPreviousImage}>
              {"<"}
            </button>
            <button className="slider-btn next-btn" onClick={goToNextImage}>
              {">"}
            </button>
          </>
        )}
      </div>

      <div className="product-detail">
        <div className="product-info-container">
          <div className="product-info">
            <h1 className="product-title">{product.name}</h1>
            <p className="product-description">
              {product.description || "Descripción no disponible"}
            </p>
          </div>
          <p className="product-price">S/. {product.price} SOLES</p>
        </div>

        {cartItem ? (
          <div className="cart-quantity-control-btn">
            <FontAwesomeIcon
              icon={faMinus}
              onClick={() => handleDecreaseQuantity(product.id)}
              className="cart-quantity-icon"
            />
            <span className="cart-quantity">{cartItem.quantity}</span>
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => handleIncreaseQuantity(product.id)}
              className={`cart-quantity-icon ${cartItem.quantity >= product.stock ? "disabled" : ""}`}
            />
          </div>
        ) : (
          <div
            className="add-to-cart-btn"
            onClick={() => {
              addToCart(product);
              setShowCart(true);
            }}
            disabled={product.stock <= 0}
          >
            {product.stock > 0 ? "Añadir al carrito" : "Sin stock"}
          </div>
        )}

        <a onClick={() => navigate(-1)} className="back-btn">
          Regresar
        </a>
      </div>
    </div>
  );
};

export default ProductDetails;
