import React, { useState, useEffect } from "react";
import axios from "axios";

function UsersPage() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error al cargar usuarios:", error);
    }
  };

  return (
    <div>
      <h2>Administrar Usuarios</h2>
      <ul className="dashboard-ul">
        {users.map(user => (
          <li key={user.id}>{user.email}</li>
        ))}
      </ul>
    </div>
  );
}

export default UsersPage;
