import React, { useState, useEffect } from "react";
import axios from "axios";

function CategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
      setCategories(response.data.categories || []);
    } catch (error) {
      console.error("Error al cargar categorías:", error);
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/categories`, { name: newCategory });
      setNewCategory("");
      fetchCategories();
    } catch (error) {
      console.error("Error al agregar categoría:", error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/categories/${categoryId}`);
      fetchCategories();
    } catch (error) {
      console.error("Error al eliminar categoría:", error);
    }
  };

  return (
    <div className="categories-page">
      <h2>Gestionar Categorías</h2>
      <form onSubmit={handleAddCategory}>
        <input
          type="text"
          placeholder="Nombre de la categoría"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          required
        />
        <button type="submit" className="submit">Agregar Categoría</button>
      </form>

      <h3>Categorías Existentes</h3>
      <ul className="dashboard-ul"l>
        {categories?.map((category) => (
          <li key={category.id}>
            {category.name}
            <button className="delete" onClick={() => handleDeleteCategory(category.id)}>Eliminar</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CategoriesPage;
