import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import { AuthContext } from "../../context/AuthContext";
import { ShoppingContext } from "../../context/ShoppingContext";
import MiniCart from "../MiniCart";
import axios from "axios";

function Header() {
  const { authenticatedUser, setAuthenticatedUser } = useContext(AuthContext);
  const { setShowCart, showCart } = useContext(ShoppingContext);
  const navigate = useNavigate();

  const [showDesplegable, setShowDesplegable] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    if (authenticatedUser) {
      setAuthenticatedUser(authenticatedUser);
    }
  }, [authenticatedUser, setAuthenticatedUser]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleStoreClick = () => {
    navigate("/tienda");
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className="header-container">
        <header className="header align-center">
          {isMobile && (
            <div>
              <FontAwesomeIcon
                icon={faBars}
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
          {!isMobile && (
            <nav className="navbar ml-1">
              <ul>
                <li
                  onMouseEnter={() => setShowDesplegable(true)}
                  onMouseLeave={() => setShowDesplegable(false)}
                >
                  <Link to="/tienda" onClick={handleStoreClick}>
                    TIENDA
                  </Link>
                  {/* Display header-2 when hovering over "TIENDA" */}
                  {showDesplegable && (
                    <div className="header-2">
                      <ul>
                        <li>Collares</li>
                        <li>Cinturones</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li>
                  <Link to="/colecciones">COLECCIONES</Link>
                </li>
              </ul>
            </nav>
          )}
          <div className="flex-center">
            <h1
              onClick={() => {
                handleHomeClick();
                showDesplegable && setShowDesplegable(!showDesplegable);
              }}
            >
              LA MONA
            </h1>
          </div>

          {isMobile && (
            <div className="mobile-cart" onClick={toggleCart}>
              CARRITO
            </div>
          )}

          {!isMobile && (
            <nav className="navbar mr-1">
              <ul>
                <li>
                  <Link to="/about">SOBRE NOSOTRAS</Link>
                </li>
                <li onClick={toggleCart}>CARRITO</li>
              </ul>
            </nav>
          )}
        </header>

        <div className={`sidebar ${showSidebar ? "show-sidebar" : ""}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="close-sidebar"
            onClick={toggleSidebar}
          />
          <nav className="sidebar-nav">
            <ul>
              <li>
                <Link
                  to="/tienda"
                  onClick={() => {
                    setShowSidebar(false);
                    handleStoreClick();
                  }}
                >
                  TIENDA
                </Link>
              </li>
              <ul className="submenu">
                  <li>
                    <Link
                      to="/tienda"
                      onClick={() => setShowSidebar(false)}
                    >
                      Collares
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tienda"
                      onClick={() => setShowSidebar(false)}
                    >
                      Cinturones
                    </Link>
                  </li>
                </ul>
              <li>
                <Link
                  to="/about"
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                >
                  SOBRE NOSOTRAS
                </Link>
              </li>
              <li
                onClick={() => {
                  setShowSidebar(false);
                  toggleCart();
                }}
              >
                <a>CARRITO</a>
              </li>
              <li>
                <Link
                  to="/colecciones"
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                >
                  COLECCIONES
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {showCart && <MiniCart closeCart={toggleCart} />}
      </div>
    </>
  );
}

export default Header;
