import React, { createContext, useState, useEffect, useCallback } from "react";

// Crea el contexto
export const ShoppingContext = createContext();

// Componente proveedor del contexto
export const ShoppingProvider = ({ children }) => {
  // Cargar el carrito desde localStorage si existe
  const initialCart = JSON.parse(localStorage.getItem("cart")) || [];

  // Estado global para el carrito de compras
  const [cart, setCart] = useState(initialCart);
  const [total_amount, settotal_amount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [deliveryId, setDeliveryId] = useState(null);

  // useEffect para actualizar localStorage cuando el carrito cambie
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // Función para agregar un producto al carrito
  const addToCart = (product) => {
    const existingProduct = cart.find((item) => item.id === product.id);

    if (existingProduct) {
      // Si el producto ya está en el carrito, actualiza la cantidad
      setCart(
        cart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 } // Incrementa la cantidad
            : item
        )
      );
    } else {
      // Si no está en el carrito, lo agrega con cantidad 1
      setCart([...cart, { ...product, quantity: 1 }]);
    }
  };

  // Función para eliminar un producto del carrito
  const removeFromCart = (productId) => {
    setCart(cart.filter((product) => product.id !== productId));
  };

  const clearCart = useCallback(() => {
    setCart([]); // Vacía el carrito
    localStorage.removeItem("cart"); // Elimina el carrito del localStorage
  }, []); // Se memoriza la función y no se vuelve a recrear en cada renderizado

  // Función para reducir la cantidad de un producto en el carrito
  const reduceQuantity = (productId) => {
    const product = cart.find((item) => item.id === productId);
    if (product.quantity === 1) {
      // Si la cantidad es 1, eliminar el producto del carrito
      removeFromCart(productId);
    } else {
      setCart(
        cart.map((item) =>
          item.id === productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
      );
    }
  };

  const [showCart, setShowCart] = useState(false);

  // Función para aumentar la cantidad de un producto en el carrito
  const increaseQuantity = (productId) => {
    setCart(
      cart.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  // Función para disminuir la cantidad de un producto en el carrito
  const decreaseQuantity = (productId) => {
    setCart(
      cart.map((item) =>
        item.id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const calculateTotal = () => {
    const total = cart.reduce((total, item) => total + item.price * item.quantity, 0);
    settotal_amount(total);
    return total; 
  };

  return (
    <ShoppingContext.Provider
      value={{
        calculateTotal,
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        reduceQuantity,
        showCart,
        setShowCart,
        decreaseQuantity,
        increaseQuantity,
        total_amount,
        settotal_amount,
        deliveryId,
        setDeliveryId,
        clientId,
        setClientId,
        paymentMethod,
        setPaymentMethod,
      }}
    >
      {children}
    </ShoppingContext.Provider>
  );
};
