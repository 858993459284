import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(null); // Estado para el usuario autenticado
  const [loading, setLoading] = useState(true); // Estado para manejar la carga

  // Verificar el token y obtener el usuario autenticado si existe
  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('authToken'); // Obtener el token de localStorage
      if (token) {
        try {
          // Configurar el encabezado Authorization con el token
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          // Realizar la solicitud para obtener la información del usuario
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`);
          setAuthenticatedUser(response.data.user); // Establecer el usuario autenticado en el contexto
        } catch (error) {
          console.error("Error al obtener la información del usuario", error);
          setAuthenticatedUser(null); // Si el token es inválido, reiniciar el estado
          localStorage.removeItem('authToken'); // Limpiar el token inválido
        }
      }
      setLoading(false); // Deja de cargar cuando termina la verificación
    };

    checkAuthentication();
  }, []);

  const login = (token, user) => {
    // Guardar el token en localStorage
    localStorage.setItem('authToken', token);

    // Configurar el encabezado Authorization
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // Establecer el usuario autenticado
    setAuthenticatedUser(user);
  };

  const logout = () => {
    // Eliminar el token y limpiar el estado
    localStorage.removeItem('authToken');
    setAuthenticatedUser(null);
  };

  return (
    <AuthContext.Provider value={{ authenticatedUser, setAuthenticatedUser, login, logout }}>
      {!loading && children} {/* No renderizar los children hasta que termine la verificación */}
    </AuthContext.Provider>
  );
};
