import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Para obtener el id de la colección desde la URL
import './ProductList.css'; 

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

function Collection() {
  const { id } = useParams(); // Obtener el id de la colección desde la URL
  const [products, setProducts] = useState([]);
  const [collection, setCollection] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Obtener la colección por su id
    axios.get(`${process.env.REACT_APP_API_URL}/collections/${id}`)
      .then(collectionResponse => {
        setCollection(collectionResponse.data);

        // Obtener los productos asociados a la colección usando collectionId
        axios.get(`${process.env.REACT_APP_API_URL}/products/collection/${id}`)
          .then(response => {
            setProducts(response.data);
          })
          .catch(error => {
            console.error("Error al cargar productos de la colección:", error);
          });
      })
      .catch(error => {
        console.error("Error al cargar la colección:", error);
      });
  }, [id]);

  const handleProductClick = (id, productSlug) => {
    navigate(`/tienda/${id}/${productSlug}`); // Navegar a la ruta con los slugs
  };

  return (
    <div className="product-grid-container">
      {products.length === 0 ? (
        <p>Cargando productos.</p>
      ) : (
        products.map(product => (
          <div 
            key={product.id} 
            className="product-item" 
            onClick={() => handleProductClick(product.id, slugify(product.name))} // Navegar con slug
          >
            <img src={product.image1} alt={product.name} className="product-image image-front" />
            <img src={product.image2} alt={product.name} className="product-image image-back" />
            <h3 className="product-name">
              <p>{product.name}</p>
              <p>S/. {product.price}</p>
            </h3>
          </div>
        ))
      )}
    </div>
  );
}

export default Collection;
