import React, { useState, useEffect } from "react";
import axios from "axios";

function CollectionsPage() {
  const [collections, setCollections] = useState([]);
  const [newCollection, setNewCollection] = useState({
    name: "",
    description: "",
    bannerUrl: "",
    mobileUrl: "", // Nuevo campo para la URL de la versión móvil
  });

  const [editingCollection, setEditingCollection] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
      setCollections(response.data || []);
    } catch (error) {
      console.error("Error al cargar colecciones:", error);
    }
  };

  const handleAddCollection = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/collections`, newCollection);
      setNewCollection({ name: "", description: "", bannerUrl: "", mobileUrl: "" });
      fetchCollections();
    } catch (error) {
      console.error("Error al agregar colección:", error);
    }
  };

  const handleDeleteCollection = async (collectionId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/collections/${collectionId}`);
      fetchCollections();
    } catch (error) {
      console.error("Error al eliminar colección:", error);
    }
  };

  const handleEditCollection = (collection) => {
    setEditingCollection(collection);
    setIsEditing(true);
  };

  const handleUpdateCollection = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/collections/${editingCollection.id}`, editingCollection);
      setEditingCollection(null);
      setIsEditing(false);
      fetchCollections();
    } catch (error) {
      console.error("Error al actualizar colección:", error);
    }
  };

  return (
    <div className="collections-page">
      <h2>{isEditing ? "Editar Colección" : "Agregar Nueva Colección"}</h2>

      <form onSubmit={isEditing ? handleUpdateCollection : handleAddCollection}>
        <input
          type="text"
          placeholder="Nombre de la colección"
          value={isEditing ? editingCollection?.name : newCollection.name}
          onChange={(e) => {
            const value = e.target.value;
            isEditing
              ? setEditingCollection({ ...editingCollection, name: value })
              : setNewCollection({ ...newCollection, name: value });
          }}
          required
        />
        <textarea
          placeholder="Descripción"
          value={isEditing ? editingCollection?.description : newCollection.description}
          onChange={(e) => {
            const value = e.target.value;
            isEditing
              ? setEditingCollection({ ...editingCollection, description: value })
              : setNewCollection({ ...newCollection, description: value });
          }}
        />
        <input
          type="text"
          placeholder="URL de la imagen (banner)"
          value={isEditing ? editingCollection?.bannerUrl : newCollection.bannerUrl}
          onChange={(e) => {
            const value = e.target.value;
            isEditing
              ? setEditingCollection({ ...editingCollection, bannerUrl: value })
              : setNewCollection({ ...newCollection, bannerUrl: value });
          }}
        />
        <input
          type="text"
          placeholder="URL de la imagen móvil"
          value={isEditing ? editingCollection?.mobileUrl : newCollection.mobileUrl}
          onChange={(e) => {
            const value = e.target.value;
            isEditing
              ? setEditingCollection({ ...editingCollection, mobileUrl: value })
              : setNewCollection({ ...newCollection, mobileUrl: value });
          }}
        />
        <button type="submit" className="submit">{isEditing ? "Actualizar Colección" : "Agregar Colección"}</button>
        {isEditing && <button className="cancel" onClick={() => setIsEditing(false)}>Cancelar</button>}
      </form>

      <h3>Colecciones Existentes</h3>
      <ul className="dashboard-ul">
        {collections?.map((collection) => (
          <li key={collection.id}>
            <strong>{collection.name}</strong>
            <p>{collection.description}</p>
            <img src={collection.bannerUrl} alt={collection.name} style={{ width: '100px' }} />
            <img src={collection.mobileUrl} alt={`${collection.name} móvil`} style={{ width: '100px' }} />
            <button onClick={() => handleEditCollection(collection)}>Editar</button>
            <button className="delete" onClick={() => handleDeleteCollection(collection.id)}>Eliminar</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CollectionsPage;
