import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ThankYou.css";
import axios from "axios";
import { ShoppingContext } from "../context/ShoppingContext";

const ThankYou = () => {
  const { paymentMethod } = useContext(ShoppingContext); 
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const updateOrderStatus = async () => {
      const paymentId = new URLSearchParams(window.location.search).get("payment_id");
      const status = new URLSearchParams(window.location.search).get("status");
      const totalAmount = localStorage.getItem("totalAmount");
      const orderId = localStorage.getItem("orderId"); // Asegúrate de tener el orderId almacenado en el localStorage

      try {
        if (orderId) {
          await axios.put(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
            paymentId,
            status,
            totalAmount,
          });

          setLoading(false);
        } else {
          setError("No se encontró una orden para actualizar.");
          setLoading(false);
        }
      } catch (err) {
        console.error("Error al actualizar la orden:", err);
        setError("Hubo un error al procesar tu orden.");
        setLoading(false);
      }
    };

    updateOrderStatus();
  }, [paymentMethod]);

  if (loading) {
    return <p>Cargando... Estamos procesando tu pedido.</p>;
  }

  if (error) {
    return (
      <div className="error-container">
        <h2>Error</h2>
        <p>{error}</p>
        <button className="retry-btn" onClick={() => navigate("/tienda")}>
          Regresar a la tienda
        </button>
      </div>
    );
  }

  return (
    <div className="thank-you-container">
      <h1>¡Gracias por tu compra!</h1>
      <p>Tu pedido ha sido procesado exitosamente. Revisa tu correo para más detalles.</p>
      <button className="continue-shopping-btn" onClick={() => navigate("/tienda")}>
        Seguir comprando
      </button>
    </div>
  );
};

export default ThankYou;
