import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [editMode, setEditMode] = useState(false); // Controlar si estamos en modo edición
  const [editingProductId, setEditingProductId] = useState(null); // ID del producto que se está editando
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    image1: "",
    image2: "",
    image3: "", // Nueva URL para la tercera imagen
    image4: "", // Nueva URL para la cuarta imagen
    categoryId: "",
    collectionId: "",
    stock: 0,
  });

  const [excelData, setExcelData] = useState(null);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchCollections();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error al cargar productos:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`
      );
      setCategories(response.data.categories || []);
    } catch (error) {
      console.error("Error al cargar categorías:", error);
    }
  };

  const fetchCollections = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/collections`
      );
      setCollections(response.data || []);
    } catch (error) {
      console.error("Error al cargar colecciones:", error);
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/products/${editingProductId}`,
          newProduct
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/products`,
          newProduct
        );
      }
      resetForm();
      fetchProducts();
    } catch (error) {
      console.error("Error al agregar o editar producto:", error);
    }
  };

  const resetForm = () => {
    setNewProduct({
      name: "",
      description: "",
      price: "",
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      categoryId: "",
      collectionId: "",
      stock: 0,
    });
    setEditMode(false); // Salimos del modo edición
    setEditingProductId(null); // Limpiamos el ID del producto que se estaba editando
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );
      fetchProducts();
    } catch (error) {
      console.error("Error al eliminar producto:", error);
    }
  };

  const handleEditProduct = (product) => {
    setNewProduct({
      name: product.name,
      description: product.description,
      price: product.price,
      image1: product.image1,
      image2: product.image2,
      image3: product.image3, // Cargar image3
      image4: product.image4, // Cargar image4
      categoryId: product.categoryId,
      collectionId: product.collectionId,
      stock: product.stock,
    });
    setEditingProductId(product.id);
    setEditMode(true);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" }); // Convertir Excel a JSON con valores vacíos por defecto
      setExcelData(jsonData); // Guardar los datos en el estado
    };
  
    reader.readAsArrayBuffer(file);
  };
  

  const handleImport = async () => {
    if (excelData) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/products/import`, {
          products: excelData,
        });
        window.alert("Productos importados exitosamente.");
        fetchProducts(); // Refrescar productos después de la importación
        setExcelData(null); // Resetear datos del archivo importado
      } catch (error) {
        window.alert(
          `Error al importar productos: ${error.response?.data?.message}`
        );
        console.error("Error al importar productos:", error);
      }
    }
  };
  

  const handleDownloadTemplate = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([
      {
        name: "",
        description: "",
        price: "",
        image1: "", // URL de la imagen 1
        image2: "", // URL de la imagen 2
        image3: "", // URL de la imagen 3
        image4: "", // URL de la imagen 4
        categoryName: "",
        collectionName: "",
        stock: "",
      },
    ]);
  
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
    XLSX.writeFile(workbook, "template_productos.xlsx");
  };
  

  return (
    <div className="products-page">
      <h2>{editMode ? "Editar Producto" : "Gestionar Productos"}</h2>
      <form onSubmit={handleAddProduct}>
        <input
          type="text"
          placeholder="Nombre del producto"
          value={newProduct.name}
          onChange={(e) =>
            setNewProduct({ ...newProduct, name: e.target.value })
          }
          required
        />
        <input
          type="text"
          placeholder="Descripción"
          value={newProduct.description}
          onChange={(e) =>
            setNewProduct({ ...newProduct, description: e.target.value })
          }
        />
        <input
          type="number"
          placeholder="Precio"
          value={newProduct.price}
          onChange={(e) =>
            setNewProduct({ ...newProduct, price: e.target.value })
          }
          required
        />
        <input
          type="text"
          placeholder="URL de la imagen 1"
          value={newProduct.image1}
          onChange={(e) =>
            setNewProduct({ ...newProduct, image1: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="URL de la imagen 2"
          value={newProduct.image2}
          onChange={(e) =>
            setNewProduct({ ...newProduct, image2: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="URL de la imagen 3"
          value={newProduct.image3}
          onChange={(e) =>
            setNewProduct({ ...newProduct, image3: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="URL de la imagen 4"
          value={newProduct.image4}
          onChange={(e) =>
            setNewProduct({ ...newProduct, image4: e.target.value })
          }
        />

        <select
          value={newProduct.categoryId}
          onChange={(e) =>
            setNewProduct({ ...newProduct, categoryId: e.target.value })
          }
        >
          <option value="">Seleccionar categoría</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        <select
          value={newProduct.collectionId}
          onChange={(e) =>
            setNewProduct({ ...newProduct, collectionId: e.target.value })
          }
        >
          <option value="">Seleccionar colección</option>
          {collections.map((collection) => (
            <option key={collection.id} value={collection.id}>
              {collection.name}
            </option>
          ))}
        </select>
        <label>Stock:</label>
        <input
          type="number"
          placeholder="Stock"
          value={newProduct.stock}
          onChange={(e) =>
            setNewProduct({ ...newProduct, stock: e.target.value })
          }
        />
        <button type="submit">
          {editMode ? "Actualizar Producto" : "Agregar Producto"}
        </button>
      </form>

      {/* Descargar plantilla de Excel */}
      <h3>Importar Productos desde Excel</h3>
      <input type="file" accept=".xls,.xlsx" onChange={handleFileUpload} />
      <button onClick={handleImport} disabled={!excelData}>
        Importar Productos
      </button>
      <a href="#" onClick={handleDownloadTemplate}>
        Descargar Plantilla de Excel
      </a>

      {/* Mostrar tabla de productos */}
      <h3>Productos Existentes</h3>
      <table className="product-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Precio (S/.)</th>
            <th>Stock</th>
            <th>Categoría</th>
            <th>Colección</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => (
            <tr key={product.id}>
              <td>{product.name}</td>
              <td>{product.description}</td>
              <td>{product.price.toFixed(2)}</td>
              <td>{product.stock} un.</td>
              <td>
                {categories.find((cat) => cat.id === product.categoryId)
                  ?.name || "Sin categoría"}
              </td>
              <td>
                {collections.find((coll) => coll.id === product.collectionId)
                  ?.name || "Sin colección"}
              </td>
              <td>
                <button onClick={() => handleEditProduct(product)}>
                  Editar
                </button>
                <button onClick={() => handleDeleteProduct(product.id)}>
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductsPage;
