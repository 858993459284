import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./Login.css";
import { AuthContext } from '../../context/AuthContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Estado para manejar la carga
  const navigate = useNavigate();

  const { login } = useContext(AuthContext); // Obtener la función login desde el contexto

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Mostrar el indicador de carga

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
      
      if (response.status === 200) {
        // Guardar el token en localStorage
        localStorage.setItem('authToken', response.data.token);

        // Guardar la información del usuario autenticado en el contexto
        login(response.data.token, response.data.user);

        // Redirigir al usuario al dashboard o a la ruta deseada
        navigate('/dashboard');
      }
    } catch (err) {
      // Manejar diferentes errores, como credenciales inválidas o problemas de servidor
      if (err.response && err.response.status === 401) {
        setError('Credenciales incorrectas. Por favor, intenta de nuevo.');
      } else {
        setError('Ocurrió un error. Por favor, intenta más tarde.');
      }
    } finally {
      setLoading(false); // Ocultar el indicador de carga
    }
  };

  return (
    <div className="login-container">
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={loading} // Deshabilitar mientras se envía la solicitud
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={loading}
        />
        {error && <p className="error-message">{error}</p>}
        <button type="submit" disabled={loading}>
          {loading ? 'Cargando...' : 'Iniciar Sesión'} {/* Mostrar el estado de carga */}
        </button>
      </form>
      <p>¿No tienes cuenta? <Link to="/register">Regístrate aquí</Link></p>
    </div>
  );
}

export default Login;
