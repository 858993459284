import React from 'react';
import { useNavigate } from 'react-router-dom';

const Failure = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    // Lógica para redirigir al usuario al checkout o al carrito
    navigate('/checkout');
  };

  return (
    <div className="thank-you-container">
      <h2>Lo sentimos, tu pago no se pudo procesar</h2>
      <p>Hubo un problema con tu pago. Por favor, inténtalo de nuevo.</p>
      <button onClick={handleRetry} className="continue-shopping-btn">
        Volver al Checkout
      </button>
    </div>
  );
};

export default Failure;
