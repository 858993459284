import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Collections.css";

// Función para convertir el nombre a un slug
const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")           // Reemplaza espacios con guiones
    .replace(/[^\w\-]+/g, "")       // Remueve caracteres especiales
    .replace(/\-\-+/g, "-")         // Reemplaza múltiples guiones por uno
    .replace(/^-+/, "")             // Remueve guiones al principio
    .replace(/-+$/, "");            // Remueve guiones al final
};

function Collections() {
  const [collections, setCollections] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Estado para detectar si es móvil
  const navigate = useNavigate();

  // Cargar colecciones desde el backend
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const collectionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
        const productsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/products`);

        // Agregar información de disponibilidad según los productos existentes
        const updatedCollections = collectionsResponse.data.map((collection) => {
          const collectionProducts = productsResponse.data.filter(
            (product) => product.collectionId === collection.id
          );
          return {
            ...collection,
            isAvailable: collectionProducts.length > 0,
            slug: slugify(collection.name), // Crear slug para la URL
          };
        });

        // Ordenar las colecciones: primero por fecha de creación (descendente), luego por disponibilidad
        const sortedCollections = updatedCollections.sort((a, b) => {
          if (b.isAvailable === a.isAvailable) {
            // Si ambas colecciones tienen el mismo estado de disponibilidad, ordenar por fecha de creación
            return new Date(b.createdAt) - new Date(a.createdAt);
          }
          // Ordenar las colecciones con productos disponibles primero
          return b.isAvailable - a.isAvailable;
        });

        setCollections(sortedCollections);
      } catch (error) {
        console.error("Error al cargar colecciones:", error);
      }
    };

    fetchCollections();
  }, []);

  // Actualizar 'isMobile' cuando la ventana se redimensiona
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCollectionClick = (id, slug, name) => {
    // Navegar enviando tanto el slug como el nombre a los params
    navigate(`/colecciones/${id}/${slug}`, { state: { name } }); // Enviar el 'name' como parte del state
  };

  return (
    <div className="collections-container">
      <div className="collections-grid">
        {collections.map((collection) => (
          <div key={collection.id} className="collection-item">
            <img 
              src={isMobile ? collection.mobileUrl : collection.bannerUrl} 
              alt={collection.name} 
              className="banner-img" 
            />
            <div className="collection-overlay">
              <button 
                onClick={() => handleCollectionClick(collection.id, collection.slug, collection.name)} // Enviar 'slug' y 'name'
                disabled={!collection.isAvailable} // Desactivar el botón si no hay productos
              >
                {collection.isAvailable ? "Ver productos" : "Stock agotado"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Collections;