import React from "react";
import "./Footer.css";


function Footer() {
  return (
    <div className="footer">
      <a href="/login" className="login">login</a>
      <div className="social-links">
      {/* <Link
          to="/tienda"
        >
          TIENDA ONLINE
        </Link> */}
        <a
          href="https://www.instagram.com/la_mona________/"
          target="_blank"
          rel="noreferrer"
        >
          INSTAGRAM
        </a>
        {/* Agrega más enlaces si es necesario */}{" "}
        <a
          href="https://www.instagram.com/la_mona________/"
          target="_blank"
          rel="noreferrer"
        >
          CONTÁCTANOS
        </a>
        {/* Agrega más enlaces si es necesario */}
      </div>
    </div>
  );
}

export default Footer;
