import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./CheckoutPayment.css";
import { useNavigate } from "react-router-dom";
import { ShoppingContext } from "../context/ShoppingContext";

const CheckoutPayment = () => {
  const { cart, calculateTotal, clearCart } = useContext(ShoppingContext); 
  const [formData, setFormData] = useState({
    email: "",
    nombre: "",
    apellidos: "",
    dni: "",
    telefono: "",
    departamento: "",
    provincia: "",
    distrito: "",
    direccion: "",
    numero: "",
    codigoPostal: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Validar productos antes de enviarlos
      if (!cart || cart.length === 0) {
        throw new Error("El carrito está vacío.");
      }
  
      // Verificar si todos los productos tienen un ID y una cantidad válida
      const validProducts = cart.every(
        (product) => product.id && product.quantity > 0
      );
  
      if (!validProducts) {
        throw new Error("Uno o más productos en el carrito no tienen ID o cantidad válida.");
      }
  
      // Crear o buscar cliente
      const clientResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients`,
        {
          email: formData.email,
          nombre: formData.nombre,
          apellidos: formData.apellidos,
          dni: formData.dni,
          telefono: formData.telefono,
        }
      );
  
      const clientId = clientResponse.data.id;
      localStorage.setItem("clientId", clientId); // Guardar clientId en localStorage
  
      // Crear dirección de entrega
      const deliveryResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/deliveries`,
        {
          department: formData.departamento,
          province: formData.provincia,
          district: formData.distrito,
          address: formData.direccion,
          number: formData.numero,
          postalCode: formData.codigoPostal,
          clientId: clientId,
        }
      );
  
      const deliveryId = deliveryResponse.data.id;
      localStorage.setItem("deliveryId", deliveryId); // Guardar deliveryId en localStorage
  
      // Crear la orden en el backend
      const orderResponse = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, {
        clientId,
        deliveryId,
        total_amount: calculateTotal(), // Total del carrito
        products: cart, // Pasamos el carrito con productos
        paymentMethod: "WhatsApp", // Método de pago por WhatsApp
      });
  
      const orderId = orderResponse.data.id; // Obtener el ID de la orden creada
  
      // Generar el mensaje de WhatsApp con los detalles del pedido
      const productos = cart
        .map((product) => `${product.name} x ${product.quantity} - S/. ${product.price * product.quantity}`)
        .join("%0A"); // %0A es equivalente a un salto de línea en URL
  
      const total_amount = calculateTotal();
      const mensaje = `Hola, deseo realizar el siguiente pedido:%0A%0A${productos}%0A%0ADatos de entrega:%0A- Nombre: ${formData.nombre} ${formData.apellidos}%0A- DNI: ${formData.dni}%0A- Teléfono: ${formData.telefono}%0A- Dirección: ${formData.direccion} ${formData.numero}, ${formData.distrito}, ${formData.provincia}, ${formData.departamento}%0A- Código Postal: ${formData.codigoPostal}%0ATotal a pagar: S/. ${total_amount}%0A%0AOrden ID: ${orderId}`;
  
      // Redirigir a WhatsApp con el mensaje generado
      const whatsappURL = `https://wa.me/51993430121?text=${mensaje}`;
      window.location.href = whatsappURL;
      clearCart();
    } catch (error) {
      console.error("Error al procesar el pedido:", error.message);
    }
  };


  return (
    <div className="checkout-payment-container">
      <form onSubmit={handleSubmit}>
        {/* Formulario de cliente */}
        <div className="form-section">
          <h2>IDENTIFICACIÓN</h2>
          <div className="form-row full-width">
            <div className="input-container">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label>Correo</label>
            </div>
          </div>

          <div className="form-row">
            <div className="input-container">
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
              <label>Nombre</label>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="apellidos"
                value={formData.apellidos}
                onChange={handleChange}
                required
              />
              <label>Apellidos</label>
            </div>
          </div>

          <div className="form-row">
            <div className="input-container">
              <input
                type="text"
                name="dni"
                value={formData.dni}
                onChange={handleChange}
                required
              />
              <label>DNI</label>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                required
              />
              <label>Teléfono</label>
            </div>
          </div>
        </div>

        {/* Información de envío */}
        <h2>ENVÍO Y ENTREGA</h2>
        <div className="form-section">
          <div className="form-row">
            <div className="input-container">
              <input
                type="text"
                name="departamento"
                value={formData.departamento}
                onChange={handleChange}
                required
              />
              <label>Departamento</label>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="provincia"
                value={formData.provincia}
                onChange={handleChange}
                required
              />
              <label>Provincia</label>
            </div>
          </div>

          <div className="form-row">
            <div className="input-container">
              <input
                type="text"
                name="distrito"
                value={formData.distrito}
                onChange={handleChange}
                required
              />
              <label>Distrito</label>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="direccion"
                value={formData.direccion}
                onChange={handleChange}
                required
              />
              <label>Dirección</label>
            </div>
          </div>

          <div className="form-row">
            <div className="input-container">
              <input
                type="text"
                name="numero"
                value={formData.numero}
                onChange={handleChange}
                required
              />
              <label>Número</label>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="codigoPostal"
                value={formData.codigoPostal}
                onChange={handleChange}
                required
              />
              <label>Código postal</label>
            </div>
          </div>
        </div>

        {/* Botón de finalizar compra */}
        <button type="submit" className="finalize-btn">
          FINALIZAR COMPRA
        </button>
      </form>
    </div>
  );
};

export default CheckoutPayment;
