import React, { useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { AuthContext } from "../context/AuthContext";

function Dashboard() {
  const { logout } = useContext(AuthContext); // Obtener la función de logout desde el contexto
  const navigate = useNavigate();

  // Manejar la acción de cerrar sesión
  const handleLogout = () => {
    logout(); // Llamar a la función logout para cerrar sesión
    navigate("/login"); // Redirigir al usuario a la página de login
  };

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <div className="panel-title">
          <h2>Panel de Administración</h2>
          <a href="/tienda" target="_blank">ver tienda</a>
        </div>
        <ul>
          <li>
            <Link to="/dashboard/categories">Categorías</Link>
          </li>
          <li>
            <Link to="/dashboard/collections">Colecciones</Link>
          </li>
          <li>
            <Link to="/dashboard/products">Productos</Link>
          </li>
          <li>
            <Link to="/dashboard/orders">Órdenes</Link>
          </li>
          <li>
            <Link to="/dashboard/clients">Clientes</Link>
          </li>
          <li>
            <Link to="/dashboard/users">Usuarios</Link>
          </li>
        </ul>
        {/* Enlace de cerrar sesión */}
        <button className="logout-button" onClick={handleLogout}>
          Cerrar sesión
        </button>
      </aside>
      <main className="dashboard-content">
        <Outlet /> {/* Donde los subcomponentes serán renderizados */}
      </main>
    </div>
  );
}

export default Dashboard;
